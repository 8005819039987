import axios from "axios"
import auth from "./auth"


const BASE_URL = "http://orscloud.com:8200"

class Http{
    constructor(){
        this.http = axios.create({
            baseURL: BASE_URL
        })

        //设置拦截器，用来添加JWT token
        this.http.interceptors.request.use(config => {
            const token = auth.token;
            if (token && token != 'undefined'){
                config.headers.common.Authorization = "JWT " + token
            }
            return config
        })

        //设置拦截器，如果返回403，则跳转到登陆页面
        //403表示没有权限访问该接口
        this.http.interceptors.response.use(null,err => {
            if (err && err.response && err.response.status == 403) {
                auth.clearUserToken()
            }
            return Promise.reject(err);
        })
    }

    login(params){
        const url = "auth/login"
        return this.http.post(url,params)
    }

    addProject(params){
        const url = "/project"
        return this.http.post(url,params)
    }

    getProject(){
        const url = "/project"
        return this.http.get(url)
    }

    editProject(project_id,params){
        const url="/project/" + project_id
        return this.http.put(url,params)
    }

    deleteProject(project_id){
        const url="/project/" + project_id
        return this.http.delete(url)       
    }

    getProjectDetail(project_id){
        const url="/project/" + project_id
        return this.http.get(url)
    }

    addHost(params){
        const url="/host"
        return this.http.post(url,params)
    }

    deleteHost(host_id){
        const url="/host/" + host_id
        return this.http.delete(url)
    }

    editUser(params){
        const url = "/auth/user"
        return this.http.put(url,params)
    }

    getUser(){
        const url = "auth/user"
        return this.http.get(url)
    }

    getIndexCount(){
        const url = "/index_count"
        return this.http.get(url)
    }
}

export default new Http()
