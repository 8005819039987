<template>
  <div>
    <div class="project-title">
        <h4>项目列表</h4>
        <div class="add_project">
            <el-button type="primary" icon="el-icon-plus" @click="addProject" size="mini" >添加项目</el-button>
        </div>       
    </div>
    <el-table :data="projects" style="width:100%">
        <el-table-column prop="name" label="项目名称" width="200">
            <template slot-scope="scope">
                <router-link :to="'/project/'+scope.row.id">{{scope.row.name}}</router-link>
            </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width=100></el-table-column>
        <el-table-column label="最后修改时间">
            <template slot-scope="scope">
                {{scope.row.last_update_time | dateFormat}}
            </template>
        </el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="状态" width="100">
            <template>
                <el-tag type="success">可用</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template slot-scope="scope">
                <el-button @click="editProject(scope.row)" type="text">编辑</el-button>
                <el-button @click="deleteProject(scope.row)" type="text">删除</el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-dialog :title="this.dialog.title" :visible.sync="dialogVisible" @closed="handleClose">
        <el-form ref="projectForm" :model="projectForm" label-width="80px" :rules="projectRules">
            <el-form-item label="名称" prop="name">
                <el-input v-model="projectForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="type"> 
                <el-select v-model="projectForm.type" placeholder="请选择类型">
                    <el-option label="Web" value="web"></el-option>
                    <el-option label="App" value="app"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="描述" prop="description">
                <el-input type="textarea" v-model="projectForm.description" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialgo-footer">
            <el-button @click="dialogVisible = false" >取 消</el-button>
            <el-button type="primary" @click="onSubmitProject"  :loading="addProjectButtonLoading">{{dialog.buttonName}}</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
export default {
    name: "ProjectList",
    data(){
        return{ 
            projects:[],
            dialogVisible: false,
            projectForm:{
                name:"",
                type:"",
                description:"",
            },
            dialog: {},
            addProjectButtonLoading: false,
            projectRules:{
                name: [
                    {required: true,message: "请输入项目名称！",trigger:"blur"}
                ],
                type: [
                    {required: true,message: "请选择项目类型！",trigger:"blur"}
                ]
            }
        }
    },
    mounted(){
        this.$http.getProject().then(res =>{
            this.projects = res.data
        })
    },
    methods:{
        initprojectForm(){
            this.projectForm = {
                name:"",
                type:"",
                description:"",                
            }
        },
        handleClose(){
            this.$refs.projectForm.resetFields();
            this.addProjectButtonLoading = false
        },
        addProject(){
            this.initprojectForm()
            this.dialogVisible = true
            this.dialog = {
                type: "add",
                title: "新增项目",
                buttonName: "添加",
            }
        },
        editProject(project){
            this.dialog = {
                type: "edit",
                title: "编辑项目",
                buttonName: "确定",
            }
            this.projectForm = {
                name: project.name,
                type: project.type,
                description: project.description,
                id: project.id,
            }
            this.dialogVisible = true
        },
        onSubmitProject(){
            this.$refs['projectForm'].validate(valid =>{
                if(!valid){
                    return
                }
            })

            if(this.dialog.type == "edit"){ 
                this.$http.editProject(this.projectForm.id,this.projectForm).then(res =>{
                    this.addProjectButtonLoading = true
                    if(res){
                        const project = res.data
                        let index = 0
                        for(let loop_project of this.projects){
                            if(loop_project.id == project.id){
                                // Vue.set(this.projects,index,project)
                                this.projects.splice(index,1,project)
                                break
                            }
                            index++
                        }
                        this.dialogVisible = false
                        this.$message.success("编辑项目成功！")
                    }
                })
            }else if(this.dialog.type == "add"){
                this.$http.addProject(this.projectForm).then(res => {
                    this.addProjectButtonLoading = true
                    if(res && res.status == 201){
                        const project = res.data
                        this.projects.push(project)
                        this.dialogVisible = false
                        this.$message.success("添加项目成功！")
                    }
                })                
            }
        },
        deleteProject(project) {
            const h = this.$createElement;
            this.$msgbox({
            title: '消息',
            message: h('p', null, [
                h('span', null, '确认删除项目 '),
                h('i', { style: 'color: teal' }, project.name),
                h('span', null, ' 吗?'),
            ]),
            type: "warning",
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                    this.$http.deleteProject(project.id).then(res => {
                            let index = 0
                            for(let loop_project of this.projects){
                                if(loop_project.id == project.id){
                                    this.projects.splice(index,1)
                                    break
                                }
                                index++
                            }
                            done();
                    }).catch(err => {
                        this.$message.error("删除项目失败！")
                        done();
                    })
                } else {
                    done();
                }
            }
            });
      }
    }
}
</script>

<style scoped>
    .project-title{
        display: flex;
        justify-content: space-between;
    }
    .add_project{
        padding-bottom: 10px;
    }
</style>