<template>
<div>
    <h4 class="page-title">欢迎使用Test API测试平台</h4>
    <el-card class="box-card">
        <div class="card-list-group">
            <el-row :gutter="10">
                <el-col :span="5">
                    <el-card>
                        <div slot="header">项目总数</div>
                        <div class="card-body">{{count.project}}</div>
                    </el-card>
                </el-col>
                <el-col :span="3">
                    <el-card>
                        <div slot="header">Host总数</div>
                        <div class="card-body">{{count.host}}</div>
                    </el-card>
                </el-col>
                <el-col :span="3">
                    <el-card>
                        <div slot="header">用户总数</div>
                        <div class="card-body">{{count.user}}</div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
    </el-card>
</div>
</template>

<script>
    export default {
        name: "Index",
        data(){
            return{
                count:{}
            }
        },
        methods:{
            getData(){
                 this.$http.getIndexCount().then(res =>{
                    const count = res.data.count
                    this.count = count
                })               
            }
        },
        mounted(){
            this.getData()
            setInterval(() =>{
                this.getData()
            },2000)
        }
    }
</script>

<style lang="scss" scoped>
.page-title{
    margin-bottom: 10px;
}
.card-list-group{
    padding-top: 20px;
    text-align: center;
    .card-body{
        font-weight: 600;
        font-size: 30px;
    }
}

</style>