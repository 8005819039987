<template>
    <div>
        <div class="top-group">
            <el-button type="primary" icon="el-icon-plus" @click="dialogVisible=true" size="mini" >添加Host</el-button>
        </div> 

        <el-table :data="project.host_list" style="width:100%">
            <el-table-column prop="hostname" label="名称" width="200"></el-table-column>
            <el-table-column prop="host" label="host" width=400></el-table-column>
            <el-table-column prop="description" label="描述"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button type="text">编辑</el-button>
                    <el-button @click="onDeleteHost(scope.row,scope.$index)" type="text">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="新增Host" :visible.sync="dialogVisible" @closed="handleClose">
            <el-form ref="hostForm" :model="hostForm" label-width="80px" :rules="hostRules">
                <el-form-item label="名称" prop="hostname">
                    <el-input v-model="hostForm.hostname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Host" prop="host"> 
                    <el-input v-model="hostForm.host" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input type="textarea" v-model="hostForm.description" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialgo-footer">
                <el-button @click="dialogVisible = false" >取 消</el-button>
                <el-button type="primary" @click="onSubmitAddHost">确定</el-button>
            </div>
        </el-dialog>  
    </div>
</template>

<script>
export default {
    name: "HostList",
    props: ['project'],
    data(){
        return {
           dialogVisible: false,  
           hostForm: {
            name: "",
            host: "",
            description: "",
           },
           hostRules: {
                hostname: [{required: true,message: "请输入host名称！",trigger:"blur"}],
                host: [{required: true,message: "请输入host！",trigger:"blur"}]
           },
        }
    },
    methods: {
        onSubmitAddHost(){
            this.$refs['hostForm'].validate(valid => {
                if(!valid){
                    return
                }
                const params = JSON.parse(JSON.stringify(this.hostForm))
                params.project_id = this.project.id
                this.$http.addHost(params).then(res => {
                    const host = res.data
                    console.log(host)
                    this.dialogVisible = false
                    this.project.host_list.push(host)
                })
            })
        },
        handleClose(){
            this.$refs.hostForm.resetFields();
        },
        onDeleteHost(host,index){
            this.$messagebox.confirm({
                message: "您确定要删除这个Host吗？",
                confirmCallback: ()=>{
                    this.$loading.show()
                    this.$http.deleteHost(host.id).then(res=> {
                        this.project.host_list.splice(index,1)
                        this.$loading.hide()
                        this.$message.success()
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang='scss'>
.top-group{
    padding-bottom: 10px;
    text-align: right;
}
</style>