<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="项目介绍" name="1">
                <ProjectInfo :project="project"></ProjectInfo>
            </el-tab-pane>
            <el-tab-pane label="项目Host" name="2">
                <HostList :project="project"></HostList>
            </el-tab-pane>
            <el-tab-pane label="API接口" name="3">API接口</el-tab-pane>
            <el-tab-pane label="测试用例" name="4">测试用例</el-tab-pane>
            <el-tab-pane label="运行记录" name="5">运行记录</el-tab-pane>
        </el-tabs>        
    </div>
</template>

<script>
import ProjectInfo from "./ProjectInfo"
import HostList from "./HostList"
export default {
    name: "ProjectDetail",
    data(){
        return {
            activeName: "1",
            project: {},
        }
    },
    components: {
        ProjectInfo,
        HostList
    },
    mounted(){
        const project_id = this.$route.params.project_id
        this.$http.getProjectDetail(project_id).then(res => {
            const project = res.data
            this.project = project
        })
    }

}
</script>

<style>

</style>