<template>
<div>
    <h4>成员列表</h4>
    <el-table :data="user_list" style="width:100%">
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="telephone" label="手机号"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column label="加入时间">
            <template slot-scope="scope">
                {{scope.row.date_joined | dateFormat}}
            </template>
        </el-table-column>
        <el-table-column prop="is_active" label="状态">
            <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.is_active">可用</el-tag>
                <el-tag type="success" v-if="scope.row.is_active==false">冻结</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button type="danger" @click="PPP(scope.row)" size="mini">禁用</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
export default {
    name: "UserList",
    data(){
        return{
            user_list: []
        }
    },
    mounted(){
        this.$http.getUser().then(res => {
            this.user_list = res.data
        })
    },
    methods:{
        PPP(pro){
            alert(pro.uid)
        }
    }
}
</script>

<style scoped>
    h4{
        margin-bottom: 15px;
    }
</style>