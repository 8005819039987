<template>
    <div>
       <div class="login-container">
            <el-row>
                <el-col :span="8" :offset="8">
                    <h3 class="page-title" align="center">Test测试平台</h3>
                    <el-form ref="loginForm" :model="loginForm" label-width="80px" class="login-form" :rules="rules">
                        <el-form-item label="手机" prop="username">
                            <el-input v-model="loginForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password" :error="errorMsg">
                            <el-input v-model="loginForm.password" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">登陆</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
       </div>
    </div>
</template>

<script>
    import axios from "axios"
    export default {
        name: "Login",
	    data(){
            let validateTel = (rule,value,callback) => {
                if(!value.match(/^1[0-9]{10}$/)){
                    callback(new Error("请输入正确的手机号码！"))
                }else{
                    callback()
                }
            }

            return{
                loginForm:{
                    username:"",
                    password:""
                },
                errorMsg:"",
                rules:{
                    username:[
                        {required:true,message:"请输入手机号码！",trigger:"blur"},
                        {validator:validateTel,trigger:"blur"}
                    ],
                    password:[
                        {required:true,message:"请输入密码！",trigger:"blur"},
                        {min:3,max:20,message:"密码字符数为3-20之间！",trigger:"blur"},
                    ]
                }
            }
        },
        methods:{
            onSubmit(){
                this.$refs['loginForm'].validate(valid => {
                    if(!valid){
                        return;
                    }

                    this.$http.login(this.loginForm).then(res => {
                        const data = res.data
                        const token = data.token
                        const user = data.user
                        if (token && token != 'undefined'){
                            this.$auth.setUserToken(user,token)
                            this.$router.push("/")
                        }else{
                            this.errorMsg = new String("用户名或密码错误!!!")
                        }
                    })
                })
            }
        }    
    }
</script>

<style lang="scss" scoped>

</style>