import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import http from './utils/http'
import auth from './utils/auth'
import message from './utils/message'
import messagebox from './utils/messagebox'
import loading from './utils/loading'
import router from './router/index.js'
import VueRouter from 'vue-router'
import filters from "./utils/filters"

Vue.config.productionTip = false
Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.prototype.$auth = auth
Vue.prototype.$http = http
Vue.prototype.$message = message
Vue.prototype.$messagebox = messagebox
Vue.prototype.$loading = loading
Vue.use(VueRouter)

//注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key,filters[key])
})

new Vue({
  //render: h => h(App),
  render(h){
      return h(App)

  },
  router
}).$mount('#app')
