<template>
    <div>
        <el-container class="frame-container">
            <el-header class="header">
                <router-link to="/" class="brand"><strong>Test</strong>API测试平台</router-link>
                <div class="header-content">
                    <ul class="menu-list">
                        <li :class="activeIndex == 1 ? 'active': ''">
                            <router-link to="/">首页</router-link>
                        </li>
                        <li :class="activeIndex == 2 ? 'active': ''">
                            <router-link to="/project">项目管理</router-link>
                        </li>
                        <li :class="activeIndex == 3 ? 'active': ''">
                            <router-link to="/user">成员管理</router-link>
                        </li>
                    </ul>
                    <div class="signout">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <img class="avatar-img" :src=" $auth.user.avatar || 'http://orscloud.com:8200/static/images/users/default.png'"/>
                                {{$auth.user.username}}
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <router-link to="/settings">设置</router-link>
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="onLogout">退出登陆</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-container class="content">
                <el-main class="main">
                    <router-view></router-view>
                </el-main>
                <el-footer class="footer">Footer</el-footer>
            </el-container>
        </el-container>
        
    </div>
</template>

<script>
    export default {
        name: "Frame",
        data(){
            return {
                activeIndex: 1
            }
        },
        components: {},
        methods:{
            onLogout(){
                this.$auth.clearUserToken() 
                this.$router.replace("/login")
            },

        },
        watch:{
            "$route"(to,from){
                if(to.path == '/'){
                    this.activeIndex = 1
                }else if(to.path.indexOf("project") > 0){
                    this.activeIndex = 2
                }else if(to.path.indexOf("user") > 0){
                    this.activeIndex =3
                }else if(to.path.indexOf("settings") > 0){
                    this.activeIndex =0
                }
            }
        }
    }
</script>

<style>
body{
    background-color: #E5E4E2;
}
*{
    margin: 0;
    padding: 0;
}
.el-dropdown-link{
    display: flex;
    align-items: center;
}
</style>
<style scoped>

.frame-container .header{
    background-color:#ADD8E6;
    display: flex;
    line-height: 60px;
    user-select:none;
   
}
a{
    width:150px;
    text-align: center;
    color:black;
    text-decoration: none;
}
.brand{
    background-color: #4682B4;
}
.frame-container .header .header-content{
    display: flex;
    margin-left: 20px;
    flex:1;
    justify-content:space-between;
}
.frame-container .header .header-content .signout{
    margin-right:30px;
}
.frame-container .header .header-content .menu-list{
    display: flex;
}
.frame-container .header .header-content .menu-list li{
    width:80px;
    list-style: none;
    text-align: center;
    color: black;
    font-size: 14px;
}
.active{
    border-bottom: 3px solid #00BFFF;
}

.signout img{
    width:24px;
    width:24px;
    margin-right: 6px;
}

.content .main{
    height:515px;
}
.content .footer{
    height:50px;
    background-color:#969292;
    flex-grow:0;
}

.aside >>> .el-menu{
    border-right: none;
}
.el-menu >>> .el-submenu__title i {
    color: #fff;
}
.el-menu >>> .el-submenu__title{
    font-size: 16px;
}
.el-menu >>> .el-menu-item{
    font-size: 16px;
    color: #fff;
}
.el-menu >>> .el-menu-item i{
    font-size: 16px;
    color:#fff;
}
</style>